.ChatMessages {
	// display: flex;
	// flex-flow: column nowrap;
	max-height: 350px;
	overflow-y: auto;
	padding-right: 2px;
}

.ChatMessage {
	display: flex;
	flex-flow: row wrap;
	flex: 0 0 auto;
	padding: 0.5em;
	margin-bottom: 1em;
	line-height: 1.7em;
	background: #EEE;
	border-radius: 0.3em;
	margin-right: 2em;
}

.ChatMessageMine {
	background: #DDD;
	text-align: right;
	justify-content: flex-end;
	align-self: flex-end;
	margin-left: 2em;
	margin-right: 0;
}

.ChatMessageText {
	margin-right: 0.5em;
}

.ChatMessageMine .ChatMessageText {
	margin-right: 0;
}

.ChatMessageMine .ChatMessageInfo {
	margin-left: 0.5em;
}
