.PagePlanesCoverText ul {
	font-size: 1.2em;
	margin-top: 1em;
}

.PagePlanesCoverText li {
	margin: 0.5em 0;
}

.PagePlanesCover {
	background: url("../img/planes-cover.jpg") no-repeat center center;
	background-size: cover;
	min-height: 200px;
}

.detalle_planes {
	.card-header {
		font-weight: bold;
		text-align: center;
		font-size: 1.2em;
		text-transform: uppercase;
	}

	.card-text {
		margin-bottom: 0;
	}

	.card-body .card-text {
		text-align: center;
		font-size: 0.9em;
	}

	.card-footer {
		text-align: center;
		margin-top: 1em;
	}

	@media (min-width: 576px) {
		.card {
			min-width: 290px;
			max-width: 290px;
			flex: 0 0 auto;
			margin: 0 0.5em !important;
		}
	}
}
