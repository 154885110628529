#PageUsersLogin {
	max-width: 550px;
}

.SocialLoginButtons {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	margin: -1em;
}

.SocialLoginButtons .btn {
	margin: 0.5em;
	min-width: 120px;
}
