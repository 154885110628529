.Page {
	margin-top: 1em;
}

.PageNarrow {
	max-width: 600px !important;
}

.PageTitle {
	display: flex;
	align-items: flex-start;
	font-size: 1.8rem;
}

.PageTitleGoBackButton {
	margin: 0 0.5em 0 0;
	height: 3.5em;
	font-size: 1.8rem;
}
