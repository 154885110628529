#PageBusquedaDespachanteDetalles .card-text {
	margin-bottom: 0em;
}

#PageBusquedaDespachanteDetalles table tr:nth-child(odd) {
	background: #EEE;
}

#PageBusquedaDespachanteDetalles table tr:nth-child(even) {
	background: #DDD;
}

#PageBusquedaDespachanteDetalles table td:first-child {
	width: 3em;
}

#PageBusquedaDespachanteDetalles table td:nth-child(2) {
	font-weight: bold;
}

#PageBusquedaDespachanteDetalles ul {
	margin-bottom: 0;
	padding-left: 1em;
}
