$pastel-red: #ff6961;

html, body {
	min-width: 100%;
	min-height: 100%;
	padding: 0;
	margin: 0;
	background: #F8F8F8;
}

body {
	overflow-y: scroll; /* force scrollbar space so the page doesn't jump horizontally when navigating from a short page to a long page which shows the scrollbar */
}

:target::before {
	content: '';
	display: block;
	height: 80px;
	margin-top: -80px;
	align-self: flex-start;
	pointer-events: none;
}

#App {
	color: #444;
	flex: 1 1 auto;
	min-height: 100vh;
	max-width: 1360px;
	margin: 0 auto;
	display: flex;
	flex-flow: column nowrap;
	--box-shadow-small: 0 0 0.5em 0em rgba(0, 0, 0, 0.2);
	--box-shadow-button: 0.05em 0.05em 0.15em 0.05em rgba(0, 0, 0, 0.2);
	--box-shadow-bottom: 0 1em 0.6em -0.6em rgba(0, 0, 0, 0.6);
	--box-shadow-header: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 15px 0px rgba(0, 0, 0, 0.14),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	--text-shadow-sm: 0.05em 0.05em 0em rgba(0, 0, 0, 0.3);
	--text-shadow: 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.7);
	--text-shadow-lg: 0.05em 0.05em 0.1em rgba(0, 0, 0, 1);
}

#AppBody {
	flex: 1 0 auto;
}

footer {
	font-size: 0.9em;
	text-align: center;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	border-top: 2px solid #EEE;
}

#MainNavbar {
	// border-bottom: 2px solid #CCC;
	text-align: right;
}

.DropdownTogglePlain {
	box-shadow: none !important;
}

.DropdownTogglePlain svg {
	margin: 0 !important;
}

.DropdownTogglePlain::after {
	display: none;
}

.navlink-current {
	font-weight: bold;
}

.PaddedPage {
	margin-top: 2em;
}

.NarrowPage {
	max-width: 500px;
}

.form-error-message {
	color: $pastel-red;
}

label.required:after {
	content: "*";
	color: $pastel-red;
}

.btn {
	font-weight: normal;
	text-shadow: var(--text-shadow-sm);
	box-shadow: var(--box-shadow-button);
}

a,
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;;
}

a.nav-link {
	display: inline-flex !important;
}

.btn svg,
a svg {
	margin: 0 0.3em;
}

.btn-link {
	text-shadow: none;
	box-shadow: none;
}

.btn-light {
	text-shadow: none;
}

.btn-link.active {
	text-decoration: underline !important;
	font-weight: bold;
}

hr {
	margin: 2em 0;
}

.NavBarBrand {
	font-size: 1.25em;
	text-decoration: none !important;
	color: #333;
	padding: 0.3em 0;
	margin-right: 1em;
	white-space: nowrap;
}

form .alert {
	white-space: pre-wrap;
}

.alert svg,
.card-header svg {
	margin-top: -2px;
	margin-right: 0.5em;
}

.card {
	box-shadow: var(--box-shadow-small);
}

abbr {
	text-decoration: none !important;
}

.icon-spin {
	-webkit-animation: icon-spin 3s infinite linear;
	animation: icon-spin 3s infinite linear;
}

.flippy-card {
	padding: 0 !important;
	box-shadow: none !important;
}

.clamp-lines__button {
	background: none;
	border: 0;
	color: #2266af;
	cursor: pointer;
	padding: 0;
}

	@-webkit-keyframes icon-spin {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(359deg);
			transform: rotate(359deg);
		}
	}

	@keyframes icon-spin {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(359deg);
			transform: rotate(359deg);
		}
	}
