.FormUsuarioDatosBasicos {
	.form-group {
		display: flex;
		align-items: flex-start;
	}

	.form-group label {
		flex: 0 0 auto;
		line-height: 2.5em;
		margin-right: 1em;
		width: 6em;
	}
}
