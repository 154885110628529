.PageComoFuncionaSteps {
	font-size: 1.3em;
	max-width: 500px;
	margin: 0 auto 3em auto;
}

.PageComoFuncionaSteps p {
	display: flex;
	align-items: center;
	justify-content: center;
}

.PageComoFuncionaSteps p svg {
	flex: 0 0 auto;
	font-size: 2em;
	margin-right: 0.3em;
}

.PageComoFuncionaFaArrowDown {
	font-size: 2em !important;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
