@import "~bootstrap/scss/bootstrap-grid.scss";

.HomepageJumbotrons {
	.jumbotron {
		margin: 0;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;

		.text {
			order: 1;
			flex: 0 1 auto;
			min-width: 100px;
		}

		.image {
			order: 0;
		}

		.image svg {
			width: 140px;
			height: 140px;
			margin: 0 2em;
		}
	}

	.jumbotron-odd {
		background: #F8F8F8;
	}

	.jumbotron-even {
		background: #EEE;
	}

	.action-button {
		margin: 0.3em !important;
	}

	.action-button svg {
		width: inherit;
		height: inherit;
	}
}

.Homepage_MainBanner {
	color: #EEE;
	text-shadow: 0.1em 0.1em 0em rgba(0, 0, 0, 0.3);
	background: url("../img/homepage_cover.jpg") top center;
	height: 400px;
	padding-top: 2em;
}

.Homepage_MainBannerText {
	font-size: 0.7em;
	padding: 0.5em;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 0.3em;
	max-width: 500px;
}

.Homepage_MainBannerText h1 {
	font-size: 2rem;
}



#Homepage_Service_Index {
	margin-top: -150px;
}

#Homepage_Service_Index .card-deck {
	border-radius: 0.3em;;
	padding-top: 15px;
	padding-bottom: 15px;
	// background: rgba(0, 0, 0, 0.15);
}

#Homepage_Service_Index .card {
	min-width: 250px;
	filter: drop-shadow(0 0 0.5em rgba(0, 0, 0, .15));
	margin-bottom: 3em;
}

#Homepage_Service_Index .card-header {
	font-size: 1.2em;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;;
}

#Homepage_Service_Index .card-header svg {
	margin-right: 0.5em;
}

#Homepage_Service_Index .card-body ul {
	padding-left: 1.5em;
}

#Homepage_Service_Index .card-body li {
	margin-bottom: 1em;
}

#Homepage_Service_Index .card-footer {
	text-align: center;
}

@include media-breakpoint-up(md) {
	.Homepage_MainBannerText {
		padding: 1em;
	}
	.Homepage_MainBannerText h1 {
		font-size: 2.5rem;
	}
}
