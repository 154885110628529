.PreLaunchBannerWrapper {
	background: url("../img/pre-launch-banner.jpg") center center no-repeat;
	background-size: cover;
	padding-top: 2em;
	padding-bottom: 2em;
	color: #333;
	border-radius: 0.5em;
	box-shadow: var(--box-shadow-header);
}

.PreLaunchBannerContent {
	padding: 2em;
	background: rgba(0, 0, 0, 0.4);
	border-radius: 0.3em;
	max-width: 700px;
	margin: 0 auto;
	text-shadow: var(--text-shadow);
	color: #FFF;
}

.PreLaunchBannerContent h2 {
	font-weight: bold;
}
