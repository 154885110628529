.BusquedaAgenteDetalles_Table {
	.Label {
		color: #666;
		font-size: 0.9em;
		text-transform: uppercase;
	}

	.Value {
		font-weight: bold;
	}
}
