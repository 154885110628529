// Override default variables before the import
// $blue: #007bff;
// $indigo: #6610f2;
// $purple: #6f42c1;
// $pink: #e83e8c;
// $red: #dc3545;
// $orange: #fd7e14;
// $yellow: #ffc107;
// $green: #28a745;
// $teal: #20c997;
// $cyan: #17a2b8;
// $white: #fff;
// $gray: #6c757d;
// $gray-dark: #343a40;
$primary: #2266af;
$secondary: #78838d;
// $success: #28a745;
// $info: #17a2b8;
// $warning: #ffc107;
// $danger: #dc3545;
// $light: #f8f9fa;
// $dark: #343a40;
// $breakpoint-xs: 0;
// $breakpoint-sm: 576px;
// $breakpoint-md: 768px;
// $breakpoint-lg: 992px;
// $breakpoint-xl: 1200px;
// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';


.w-5e {
	width: 5em;
}

.w-10e {
	width: 10em;
}

.w-20e {
	width: 20em;
}

.w-30e {
	width: 30em;
}

.w-40e {
	width: 40em;
}

.mw-5e {
	max-width: 5em;
}

.mw-10e {
	max-width: 10em;
}

.mw-20e {
	max-width: 20em;
}

.mw-30e {
	max-width: 30em;
}

.mw-40e {
	max-width: 40em;
}
